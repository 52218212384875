import { Component, inject, OnInit } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
  RouterLink,
} from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { ViewContentComponent } from 'src/app/components/_layout/view-content/view-content.component';
import { ViewHeaderComponent } from 'src/app/components/_layout/view-header/view-header.component';
import { LocationsService } from 'src/app/services/locations.service';

export const locationsListResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(LocationsService).getLocationsList();
};
@Component({
  selector: 'app-locations-view',
  templateUrl: './locations.view.html',
  styleUrls: ['./locations.view.scss'],
  standalone: true,
  imports: [
    ViewHeaderComponent,
    ViewContentComponent,
    MatRipple,
    SvgIconComponent,
    RouterLink,
  ],
  host: {
    id: 'view',
  },
})
export class LocationsViewComponent implements OnInit {
  public locations = [];

  constructor(private _route: ActivatedRoute) {
    const { locations } = _route.snapshot.data || {};
    this.locations = locations?.items ?? [];
    console.log(this.locations);
  }

  ngOnInit() {}
}
